@import '../../style/themes/default';
@import '../../style/mixins/index';

@result-prefix-cls: ~'@{ant-prefix}-result';

.@{result-prefix-cls} {
  padding: 48px 32px;
  // status color
  &-success &-icon > .anticon {
    color: @success-color;
  }

  &-error &-icon > .anticon {
    color: @error-color;
  }

  &-info &-icon > .anticon {
    color: @info-color;
  }

  &-warning &-icon > .anticon {
    color: @warning-color;
  }

  // Exception Status image
  &-image {
    width: 250px;
    height: 295px;
    margin: auto;
  }

  &-icon {
    margin-bottom: 24px;
    text-align: center;

    > .anticon {
      font-size: 72px;
    }
  }

  &-title {
    color: @heading-color;
    font-size: 24px;
    line-height: 1.8;
    text-align: center;
  }

  &-subtitle {
    color: @text-color-secondary;
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
  }

  &-extra {
    margin-top: 32px;
    text-align: center;
    > * {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-content {
    margin-top: 24px;
    padding: 24px 40px;
    background-color: @background-color-light;
  }
}

@primary-color: #253772;@link-color: #0e83ea;@success-color: #229963;@warning-color: #fcb813;@error-color: #ec1a36;@heading-color: #001233;@text-color: #001233;@text-color-secondary: #3a3a56;@text-color-inverse: #ffffff;@text-color-dark: #a7a7ba;@text-color-dark-secondary: #838393;@disabled-color: #a7a7b1;@btn-primary-bg: #0e83ea;@item-hover-bg: #f0f0ef;@layout-body-background: #ffffff;@layout-sider-background: #f0f0ef;@layout-trigger-background: #f0f0ef;@menu-inline-toplevel-item-height: 60px;@menu-collapsed-width: 95px;@menu-bg: #f0f0ef;@menu-item-active-bg: #ffffff;@border-radius-base: 0;@border-color-base: #f0f0ef;@box-shadow-base: 0 2px 8px #f0f0ef;@table-row-hover-bg: #f0f0ef;@table-header-color: #253772;@select-item-selected-bg: #f0f0ef;@select-item-active-bg: #f0f0ef;@font-family: "NeuzeitGrotesk", -apple-system, BlinkMacSystemFont, "Segoe UI","Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;@font-size-base: 16px;@font-size-lg: 16px;